import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph } from "../../text/paragraph/paragraph";
import { SrOnly } from "../../text/sr-only/sr-only";
import { TextContainer } from "../../text/text-container/text-container";
import { Ul } from "../../text/list/list";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Code,
	DocsTable,
	Header,
} from "../../../../../src/docs";
import { Badge } from "./badge";

export const Meta: DocPageMeta = {
	category: "Visuals",
	title: "Badge",
	notepad: "https://hackmd.io/k6Krz7jjRuO1_CbrhNxSpg",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Badge"
			subTitle="Use this component to highlight the status, category, or numeric value of an item."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Badge} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The <Code>Badge</Code> component features a few properties for adjusting its appearance.
					The following sections showcase these properties and explain when and how to use them.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					This is the default appearance of the <Code>Badge</Code> component. It’s <Code>type</Code>
					, <Code>variant</Code>, and <Code>size</Code> props are by default set to{" "}
					<Code>neutral</Code>, <Code>dark</Code>, and <Code>medium</Code>, respectively.
				</Paragraph>
				<Example fn={DefaultExample} />
				<Header.H3>Types</Header.H3>
				<Paragraph>
					Use the <Code>type</Code> property to change the color of a <Code>badge</Code>. Every
					color has a specific semantic meaning or purpose. Consult the table below to find out
					which badge type suits your use case.
				</Paragraph>
				<Paragraph>
					The last three types (<Code>highlight1</Code>, <Code>highlight2</Code> and{" "}
					<Code>highlight3</Code>) share the same, more general purpose. Use these when the other
					types don’t apply to your use case. For example, you might have items on a page that refer
					to accessibility issues. You could then use the three highlight types to communicate the
					conformance level (A, AA, or AAA) of each accessibility issue.
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Type</th>
							<th>Meaning/Purpose</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<Badge type="neutral">neutral</Badge>
							</td>
							<td>Something is neither important, unimportant, negative or positive.</td>
						</tr>
						<tr>
							<td>
								<Badge type="subtle">subtle</Badge>
							</td>
							<td>Something is relatively unimportant.</td>
						</tr>
						<tr>
							<td>
								<Badge type="positive">positive</Badge>
							</td>
							<td>Something has gone well or is going well.</td>
						</tr>
						<tr>
							<td>
								<Badge type="warning">warning</Badge>
							</td>
							<td>Something requires attention, but isn’t critical.</td>
						</tr>
						<tr>
							<td>
								<Badge type="critical">critical</Badge>
							</td>
							<td>Something is critical and requires attention.</td>
						</tr>
						<tr>
							<td>
								<Badge type="negative">negative</Badge>
							</td>
							<td>Something has gone wrong.</td>
						</tr>
						<tr>
							<td>
								<Badge type="highlight1">highlight1</Badge>
							</td>
							<td>Something has a certain status or belongs to a certain category.</td>
						</tr>
						<tr>
							<td>
								<Badge type="highlight2">highlight2</Badge>
							</td>
							<td>Something has a certain status or belongs to a certain category.</td>
						</tr>
						<tr>
							<td>
								<Badge type="highlight3">highlight3</Badge>
							</td>
							<td>Something has a certain status or belongs to a certain category.</td>
						</tr>
					</tbody>
				</DocsTable>
				<Example fn={TypeExample} />
				<Header.H3>Variants</Header.H3>
				<Paragraph>
					Set the <Code>variant</Code> property to <Code>light</Code> if a <Code>badge</Code> needs
					to be less visually prominent. For example, when more important UI elements need to
					attract the user's attention, instead. Another use case is when there are a lot of badges
					on a page (for example, in every row of a{" "}
					<GatsbyLink to="/lab/components/Tables and lists/Table">table</GatsbyLink>). That may
					result in the badges collectively becoming visually dominating.
				</Paragraph>
				<Example fn={VariantExample} />
				<Header.H3>Sizes</Header.H3>
				<Paragraph>
					Use the <Code>size</Code> property to adjust the size of a <Code>badge</Code>. The default
					size is <Code>medium</Code> and should be used in most cases. Use <Code>small</Code> when
					space is limited, such as in the Page Report. Use <Code>large</Code> when a badge needs to
					attract extra attention or visually match its item. Badges placed next to each other
					should all have the same size (so not like in the example below).
				</Paragraph>
				<Example fn={SizeExample} />
				<Header.H3>Screen reader only</Header.H3>
				<Paragraph>
					To understand the meaning of a <Code>badge</Code>, you sometimes need more than just its
					label. You might need to see its color or placement within the UI as well. Use the{" "}
					<GatsbyLink to="/lib/components/Text/Screen reader only (SrOnly)">SrOnly</GatsbyLink>{" "}
					component to supply color-blind and non-sighted users with critical visual information
					they’re missing out on.
				</Paragraph>
				<Paragraph>
					For example, you might not realize that a badge labelled "Missing" is warning you about
					something, if you can't see its orange color. Alternatively, if you can't see the position
					of a badge, it might be unclear that its number refers to how many occurrences have been
					detected of a certain accessibility issue.
				</Paragraph>
				<Paragraph>
					The examples below show how to use the{" "}
					<GatsbyLink to="/lib/components/Text/Screen reader only (SrOnly)">SrOnly</GatsbyLink>{" "}
					component to address such problems.
				</Paragraph>
				<Example fn={ScreenReaderOnlyExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs component={Badge} initialProps={{ children: "badge" }} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							<>
								Use a <Code>badge</Code> to highlight the status, category, or numeric value of an
								item.
							</>,
							<>
								Choose a badge <a href="#types">type</a> (color) based on its associated semantic
								meaning.
							</>,
							<>
								Only use a <Code>highlight</Code> badge if the other, more semantically specific,
								types don’t fit your use case.
							</>,
							<>
								Use the <Code>highlight</Code> type badges consistently. For instance, always use{" "}
								<Code>highlight1</Code>, <Code>highlight2</Code>, and <Code>highlight3</Code> for
								accessibility conformance levels A, AA, and AAA, respectively.
							</>,
							<>
								Set the <Code>variant</Code> property to <Code>light</Code> if a <Code>badge</Code>{" "}
								needs to attract less attention.
							</>,
							<>
								Use <Code>small</Code> badges when space is limited, such as in the Page Report.
							</>,
							<>
								Use <Code>large</Code> badges when they need to attract extra attention or visually
								match their item.
							</>,
							<>Use the same size for badges placed next to each other.</>,
							<>Position badges so that it's clear which item they're related to.</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>
						The badge needs to be interactive. Instead, use a tag (hasn't yet been added to Fancy).
					</Paragraph>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						Sometimes, the meaning of a badge is unclear if you can't see its color or position
						within the UI. Use a{" "}
						<GatsbyLink to="/lib/components/Text/Screen reader only (SrOnly)">SrOnly</GatsbyLink>{" "}
						component to supply color-blind and non-sighted users with the critical visual
						information they’re missing out on.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>Keep badge labels short &mdash; no more than two words.</>,
							<>Use past tense for verbs ("Updated", not "Update").</>,
							"Capitalize the first letter of badge labels.",
						]}
					/>
				</InlineMessage>
				<Header.H2>Notable Changes</Header.H2>
				<Header.H3>Version 0.0.x</Header.H3>
				<Ul
					items={[
						<>
							The <Code>Text</Code> property has been removed and the <Code>Badge</Code> component
							is no longer self-closing. Instead, you now wrap the label (or other content) in{" "}
							<Code>Badge</Code> tags.
						</>,
						<>
							The <Code>color</Code> property has been renamed to <Code>type</Code>. Its{" "}
							<Code>blue</Code> (default), <Code>green</Code>, <Code>red</Code>, and{" "}
							<Code>yellow</Code> values have been replaced with <Code>neutral</Code> (default),{" "}
							<Code>subtle</Code>, <Code>positive</Code>, <Code>warning</Code>,{" "}
							<Code>critical</Code>, <Code>negative</Code>, <Code>highlight1</Code>,{" "}
							<Code>highlight2</Code>, and <Code>highlight3</Code>.
						</>,
						<>
							The <Code>small</Code> property has been renamed to <Code>size</Code>. Instead of
							being a boolean, it now offers the values <Code>small</Code>, <Code>medium</Code>{" "}
							(default), and <Code>large</Code>.
						</>,
						<>
							The <Code>fill</Code> property has been removed.
						</>,
					]}
				/>
			</TextContainer>
		</ContentSection>
	</>
);

const DefaultExample = () => <Badge>Badge</Badge>;

const TypeExample = () => (
	<>
		<Badge type="neutral">Neutral</Badge>
		<Badge type="subtle">Subtle</Badge>
		<Badge type="positive">Positive</Badge>
		<Badge type="warning">Warning</Badge>
		<Badge type="critical">Critical</Badge>
		<Badge type="negative">Negative</Badge>
		<Badge type="highlight1">Highlight1</Badge>
		<Badge type="highlight2">Highlight2</Badge>
		<Badge type="highlight3">Highlight3</Badge>
	</>
);

const VariantExample = () => (
	<>
		<Badge type="neutral" variant="light">
			Neutral
		</Badge>
		<Badge type="subtle" variant="light">
			Subtle
		</Badge>
		<Badge type="positive" variant="light">
			Positive
		</Badge>
		<Badge type="warning" variant="light">
			Warning
		</Badge>
		<Badge type="critical" variant="light">
			Critical
		</Badge>
		<Badge type="negative" variant="light">
			Negative
		</Badge>
		<Badge type="highlight1" variant="light">
			Highlight1
		</Badge>
		<Badge type="highlight2" variant="light">
			Highlight2
		</Badge>
		<Badge type="highlight3" variant="light">
			Highlight3
		</Badge>
	</>
);

const SizeExample = () => (
	<>
		<Badge size="small">Badge</Badge>
		<Badge size="medium">Badge</Badge>
		<Badge size="large">Badge</Badge>
	</>
);

const ScreenReaderOnlyExample = () => (
	<>
		<Badge type="warning">
			<SrOnly>Warning:</SrOnly> Missing
		</Badge>
		<Badge>
			42 <SrOnly>occurrences</SrOnly>
		</Badge>
	</>
);
